<template>
  <span>
    <el-dropdown trigger="click" :hide-on-click="false" placement="bottom-start">
      <el-button title="Сохранение вида" type="text" icon="el-icon-view" v-if="!!guid"></el-button>
      <el-dropdown-menu slot="dropdown" style="min-width: 400px">
        <el-dropdown-item
            class="dropdown-item-custom-view"
            :class="{ 'item-active': item.isActive }"
            v-for="item in userViews"
            v-bind:key="item.name">
            <i class="el-icon-check" v-if="item.isActive"></i><span @click="loadView(item)">{{ item.name }}</span>
          <span class="is_default_label" v-if="item.is_default"> {{ $locale.registry.toolbar.user_views.chosen_as_default }}</span>
            <span
                class="dropdown-item-icon"
                @click="showItemSettings(item)">
                <i class="el-icon-setting"></i>
            </span>
            <div
                v-if="item.showItemSettingsPanel">
              <el-button-group>
                  <el-button
                      type="success"
                      size="mini"
                      icon="el-icon-refresh"
                      plain
                      @click="updateView(item)">{{ $locale.registry.toolbar.user_views.save_btn }}</el-button>
                  <el-button
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      plain
                      @click="deleteView(item)">{{ $locale.registry.toolbar.user_views.delete_btn }}</el-button>
                  <el-button
                      type="primary"
                      size="mini"
                      :icon="item.is_default ? 'el-icon-check' : 'el-icon-plus'"
                      plain
                      @click="setDefaultView(item)">{{ $locale.registry.toolbar.user_views.set_default_btn }}</el-button>
                </el-button-group>
            </div>
        </el-dropdown-item>
        <el-dropdown-item icon="el-icon-s-unfold" divided>
          <span @click="loadDefaultView">{{ $locale.registry.toolbar.user_views.default_view }}</span>
        </el-dropdown-item>
        <el-dropdown-item icon="el-icon-plus" divided>
          <span @click="showNewViewItem = !showNewViewItem">{{ $locale.registry.toolbar.user_views.save_current_view }}</span>
        </el-dropdown-item>
        <div class="new-item-settings" v-show="showNewViewItem">
          <el-input
              class="new-item-settings__input"
              width="70%"
              v-model="newViewName"
              size="mini"
              :placeholder="$locale.registry.toolbar.user_views.new_view_placeholder"></el-input>
          <el-button
              class="new-item-settings__button"
              type="success"
              size="mini"
              icon="el-icon-plus"
              plain
              @click="addView">
          </el-button>
        </div>
        <div v-show="hasErrors" class="new_item_error_block">
          <p>{{ errorText }}</p>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </span>
</template>

<script>
export default {
  name: 'CustomViewSettings',
  data () {
    return {
      showNewViewItem: false,
      userViews: [],
      newViewName: '',
      hasErrors: false,
      errorText: '',
      initialLoadComplete: false,
      initialSettings: [],
      initialFilters: []
    }
  },
  props: {
    gridApi: {
      type: Object,
      default: function () {
        return {}
      }
    },
    guid: {
      type: String
    }
  },
  computed: {
    columnModel () {
      return this.gridApi.columnApi
    },
    gridModel () {
      return this.gridApi.api
    },
    userId () {
      return this.$store.getters['Authorization/userId']
    },
    currentColumnState () {
      return this.columnModel.getColumnState()
    },
    currentFilterState () {
      return this.gridModel.getFilterModel()
    }
  },
  watch: {
    newViewName () {
      this.hasErrors = false
    },
    gridApi () {
      if (this.initialLoadComplete) {
        // Сохранить изначальный вид грида
        this.initialSettings = this.currentColumnState
        this.initialFilters = this.currentFilterState

        let defaultView = this.userViews.find(el => {
          this.$set(el, 'isActive', true)
          this.setOtherViewsInactive(el.guid)
          return el.is_default
        })
        // Загрузить вид по умолчанию
        if (defaultView) {
          this.columnModel.applyColumnState({ state: JSON.parse(defaultView.settings), applyOrder: true })
          this.gridModel.setFilterModel(JSON.parse(defaultView.filters))
        }
        this.$set(this, 'initialLoadComplete', false)
      }
    }
  },
  async created () {
    await this.loadUserViews(true)
  },
  methods: {
    loadView (view) {
      this.$set(view, 'isActive', true)
      this.setOtherViewsInactive(view.guid)
      this.columnModel.applyColumnState({ state: JSON.parse(view.settings), applyOrder: true })
      this.gridModel.setFilterModel(JSON.parse(view.filters))
    },
    async updateView (view) {
      this.$set(view, 'showSettingsPanel', false)
      let params = {
        name: view.name,
        filters: this.currentFilterState,
        settings: this.currentColumnState
      }
      await this.$http.put(`${this.$config.api}/v2/accesseditor/user/view/${view.guid}`, params)
      await this.loadUserViews()
    },
    async deleteView (view) {
      this.$set(view, 'showSettingsPanel', false)
      await this.$http.delete(`${this.$config.api}/v2/accesseditor/user/view/${view.guid}`)
      await this.loadUserViews()
      // if (view.isActive) this.loadDefaultView()
    },
    async addView () {
      if (!this.newViewName.length) {
        this.hasErrors = true
        this.errorText = this.$locale.registry.toolbar.user_views.not_empty_field_error
        return
      }

      if (this.nameExists()) {
        this.hasErrors = true
        this.errorText = this.$locale.registry.toolbar.user_views.unique_name_error
        return
      }

      this.showNewViewItem = false

      let params = {
        name: this.newViewName,
        filters: this.currentFilterState,
        settings: this.currentColumnState
      }
      if (this.guid) {
        await this.$http.put(`${this.$config.api}/v2/accesseditor/user/${this.userId}/guid/${this.guid}/views`, params)
        await this.loadUserViews()
        this.$set(this, 'newViewName', '')
      }
    },
    async setDefaultView (view) {
      let params = {
        name: view.name,
        filters: JSON.parse(view.filters),
        settings: JSON.parse(view.settings),
        isDefault: true
      }

      if (view.is_default) {
        this.$set(view, 'is_default', false)
        params.isDefault = false
        await this.$http.put(`${this.$config.api}/v2/accesseditor/user/view/${view.guid}`, params)
        return
      }

      this.$set(view, 'is_default', true)
      await this.$http.put(`${this.$config.api}/v2/accesseditor/user/view/${view.guid}`, params)

      let otherViews = this.userViews.filter(el => el.guid !== view.guid)
      for (const el of otherViews) {
        this.$set(el, 'is_default', false)
        let params = {
          name: el.name,
          filters: JSON.parse(el.filters),
          settings: JSON.parse(el.settings),
          isDefault: false
        }
        await this.$http.put(`${this.$config.api}/v2/accesseditor/user/view/${el.guid}`, params, { hideNotification: true })
      }
    },
    loadDefaultView () {
      this.columnModel.applyColumnState({ state: this.initialSettings, applyOrder: true })
      this.gridModel.setFilterModel(this.initialFilters)
      this.setOtherViewsInactive(null)
    },
    showItemSettings (view) {
      this.$set(view, 'showItemSettingsPanel', !view.showItemSettingsPanel)
    },
    async loadUserViews (isFirstLoad = false) {
      if (this.guid) {
        let response = await this.$http.get(`${this.$config.api}/v2/accesseditor/user/${this.userId}/guid/${this.guid}/views`, { hideNotification: true })
        this.$set(this, 'userViews', response.data)
        if (isFirstLoad) this.$set(this, 'initialLoadComplete', true)
      }
    },
    nameExists () {
      return !!this.userViews.find(el => el.name === this.newViewName)
    },
    setOtherViewsInactive (guid) {
      this.userViews.forEach(el => {
        if (el.guid !== guid) this.$set(el, 'isActive', false)
      })
    }
  }
}
</script>

<style>
.dropdown-item-icon {
  display: none;
  position: absolute;
  right: 5px;
}

.dropdown-item-custom-view:hover .dropdown-item-icon {
  display: inline-block;
}

.new-item-settings {
  padding: 5px;
  margin: 5px;
  display: flex;
}

.new-item-settings__button {
  max-width: 70%;
  display: inline-block;
}

.new-item-settings__input {
  display: inline-block;
  margin-left: 5px;
}

.new_item_error_block {
  color: #f56c6c;
  margin-left: 15px;
}

.item-active {
  background: #ecf5ff;
}

.is_default_label {
  font-size: 0.7em;
  color: #909399;
}
</style>
