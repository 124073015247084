import FloatEditor from '@/core/infrastructure/components/Grid/infrastructure/components/CellEditors/FloatEditor'
import DateEditor from '@/core/infrastructure/components/Grid/infrastructure/components/CellEditors/DateEditor'
import DatetimeEditor from '@/core/infrastructure/components/Grid/infrastructure/components/CellEditors/DatetimeEditor'
import BooleanEditor from '@/core/infrastructure/components/Grid/infrastructure/components/CellEditors/BooleanEditor'
import NumberEditor from '@/core/infrastructure/components/Grid/infrastructure/components/CellEditors/NumberEditor'
import XrefEditor from '@/core/infrastructure/components/Grid/infrastructure/components/CellEditors/XrefEditor'
import TimeEditor from '@/core/infrastructure/components/Grid/infrastructure/components/CellEditors/TimeEditor'

export default class CellEditors {
  static MAP_EDITOR = {
    'floatField': CellEditors.float,
    'textField': CellEditors.text,
    'booleanField': CellEditors.boolean,
    'integerField': CellEditors.number,
    'xrefField': CellEditors.xref,
    'xrefMultiField': CellEditors.multiXref,
    'dateField': CellEditors.date,
    'datetimeField': CellEditors.datetime,
    'timeField': CellEditors.time
  }

  static VALUE_SETTER_MAP = {
    'xrefField': CellEditors.xrefSetter
  }

  static build (columnType: string, context: any, column: any): object {
    const cellEditorBuildFunction = this.MAP_EDITOR[columnType]
    let object = cellEditorBuildFunction ? cellEditorBuildFunction(context, column) : {}
    let valueSetterFunction = this.VALUE_SETTER_MAP[columnType]
    return Object.assign(object, {
      valueSetter: valueSetterFunction ? valueSetterFunction(context) : this.getDefaultValueSetter(context)
    })
  }

  private static getDefaultValueSetter (context) {
    return async (params) => {
      if (!params.data.id || !params.data.object_id) {
        console.log(`Отсутсвуют параметры: id записи ${params.data.id}, реестр id: ${params.data.object_id}`)
        return false
      }
      let savingObject = {
        id: params.data.id,
        object_id: params.data.object_id,
        [params.column.colDef.field]: params.newValue
      }
      try {
        await context.$http.put(`${context.$config.api}/registryservice/registry/${params.data.object_id}/records/${params.data.id}`,
          savingObject)
      } catch (e) {
        if (e.response.data.error === 'constraint_verification_failed') {
          let message = '<ul>'

          e.response.data.params.errors.forEach((item) => {
            const required = '' // item.is_allow_saving ? '(необязаьельно)' : '(обязательно)'
            message += `<li>${item.message} <strong>${required}</strong></li>`
          })

          message += '</ul>'

          context.$alert(`Необходимо исправить ошибки!${message}`, context.$locale.main.message.attention, {
            confirmButtonText: context.$locale.main.button.ok,
            type: 'error',
            dangerouslyUseHTMLString: true,
            lockScroll: true,
            callback: (action) => {}
          })
        }
        return false
      }

      context.$set(params.data, params.column.colDef.field, params.newValue)
      if (typeof context.getEventBus === 'function') {
        const eventBus = context.getEventBus()
        eventBus.$emit('update-record', params.data.id)
      }
      return true
    }
  }

  private static xrefSetter (context) {
    return (params) => {
      if (!params.data.id || !params.data.object_id) {
        return false
      }
      const newValue = params.newValue.length ? (params.newValue[0]?.id || null) : null
      let savingObject = {
        id: params.data.id,
        object_id: params.data.object_id,
        [params.column.colDef.field]: newValue
      }
      context.$http.put(`${context.$config.api}/registryservice/registry/${params.data.object_id}/records/${params.data.id}`,
        savingObject)

      context.$set(params.data, params.column.colDef.field, params.newValue)
      return true
    }
  }
  static datetime (): object {
    return {
      cellEditor: DatetimeEditor
    }
  }
  static date (): object {
    return {
      cellEditor: DateEditor
    }
  }
  static time (): object {
    return {
      cellEditor: TimeEditor
    }
  }
  static float (): object {
    return {
      cellEditor: FloatEditor
    }
  }

  static number (context: any, column): object {
    return {
      cellEditor: NumberEditor,
      cellEditorParams: {
        negativeValues: column.negativeValues
      }
    }
  }

  static text (): object {
    return {
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true
    }
  }

  static boolean (): object {
    return {
      cellEditor: BooleanEditor
    }
  }

  static xref (context: any): object {
    return {
      cellEditor: XrefEditor,
      cellEditorParams: {
        multi: false,
        axios: context.$http,
        api_url: context.$config.api
      }
    }
  }

  static multiXref (context: any): object {
    return {
      editable: false
    }
  }
}
